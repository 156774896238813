import { Route, Redirect, Switch, Link } from "react-router-dom";
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import {decryptData, capitalizeStr} from "../utils/general_functions";

// Components
import page_in_progress from "./page_in_progress"
import BillParamsAdmin from "./billing/bill_params_admin";
import UserBillsAdmin from "./billing/user_bills_admin";

function AdminPage() {
    const userData = JSON.parse(decryptData(localStorage.getItem('userData')));
    const userApps = userData.apps.filter(currApp => currApp.is_admin_page);

    const AdminPageComp = () => {
        return (
            <div>
            <Row xs={1} md={4} className="g-4">
            {userApps.map((currApp, idx) => (
                <Col key={idx}>
                <Link to={`/admin/${currApp.app_name.replace(/_admin$/, '')}`}>
                    <Card>
                        <Card.Img variant="top" src={currApp.app_img_link} style={{width:100, height:100, margin: "auto", marginTop: 10}}/>
                        <Card.Body>
                        <Card.Title>{capitalizeStr(currApp.app_name.replace(/_/g, ' '))}</Card.Title>
                        </Card.Body>
                    </Card>
                </Link>
                </Col>
            ))}
            </Row>
            </div>
        )
    };

    return (
        <Switch>
            {/* Billing specific routes */}
            <Route exact path="/admin" component={AdminPageComp}/>
            <Route exact path="/admin/bill_params" component={BillParamsAdmin}/>
            {/* <Route exact path="/admin/user_bills" component={UserBillsAdmin}/> */}
            <Route exact path="/admin/user_bills" component={page_in_progress}/>
            <Redirect from="/admin/*" to="/404"/>
        </Switch>
    );
}

export default AdminPage;
