import React, { useState, useEffect } from "react";

import { GetBillSearchParams, UpdateBillSearchParams } from "../../utils/queries";
import { formatTimestamp } from "../../utils/general_functions";

// Components
import LoadingPage from "../loading";

const findByID = (id, lst) => {
    const item = lst.filter(param => param.bill_id === id);
    return item.length > 0 ? item[0] : {};
}

function BillParamsAdmin() {
    const [searchParams, setBillSearchParams] = useState();
    const [initialParams, setInitialParams] = useState([]); // Stores original values
    const [modifiedParams, setModifiedParams] = useState([]); // Stores only changed values
    const [reviewFlg, setReviewFlg] = useState(false); // Stores only changed values
    const [updatedRecordCnt, setUpdatedRecordCnt] = useState(0); // Stores only changed values

    useEffect(() => { 
        if (typeof searchParams === 'undefined' || searchParams.length === 0) {
            GetBillSearchParams().then((data) => { setBillSearchParams(data); setInitialParams(JSON.parse(JSON.stringify(data))) });
        }
    }, []);
    
    if (typeof searchParams === 'undefined') { return <LoadingPage/> }

    // Handle form submission (e.g., send data to backend)
    const handleSubmit = (event) => {
        event.preventDefault();
        setReviewFlg(!reviewFlg);
        calculateChanges();
    }

    const submitChanges = () => {
        UpdateBillSearchParams(modifiedParams)
        .then((cnt) => setUpdatedRecordCnt(cnt));
    }

    const handleInputChange = (index, event) => {
        const { name, value } = event.target;
        const updatedSearchParams = [...searchParams];
        updatedSearchParams[index][name] = value;
        setBillSearchParams(updatedSearchParams);
        calculateChanges(updatedSearchParams);
    };

    const removeSearchParam = (index) => {
        const updatedSearchParams = searchParams.filter((_, i) => i !== index);
        setBillSearchParams(updatedSearchParams);
        calculateChanges(updatedSearchParams);
    };

    const calculateChanges = (updatedSearchParams = searchParams) => {
        // Compare initial vs modified to find changes
        const changes = updatedSearchParams.map((param, index) => {
            const original = findByID(param.bill_id, initialParams);
            const modifiedFields = {};

            Object.keys(param).forEach((key) => {
                if (param[key] !== original[key]) {
                    modifiedFields[key] = param[key];
                }
            });
            
            // We only want to track existing bill ids that have modified
            // No need to track created ones as updates
            return Object.keys(modifiedFields).length > 0 & initial_bill_ids.includes(param.bill_id) ? { bill_id: param.bill_id, ...modifiedFields } : null;
        }).filter(change => change !== null); // Remove unchanged items

        setModifiedParams(changes); // Save only changed fields
    }

    const addNewSearchParam = () => {
        setBillSearchParams([
            ...searchParams,
            {
              bill_id: "",
              bill_name: "",
              start_ts: "",
              end_ts: "",
              subject_filter: "",
              from_filter: "",
              balance_regex: "",
              additional_search_args: "[]",
            },
        ]);
    }

    const curr_bill_ids = searchParams.map(param => param.bill_id);
    const initial_bill_ids = initialParams.map(param => param.bill_id);
    const removedParams = initial_bill_ids.filter(param => !curr_bill_ids.includes(param));
    const createdParams = curr_bill_ids.filter(param => !initial_bill_ids.includes(param));

    return (
    <>
    {updatedRecordCnt !== 0 && (
        <div>{updatedRecordCnt === 1 ? `${updatedRecordCnt} record has `: `${updatedRecordCnt} records have `} been updated!</div>
    )}

    {updatedRecordCnt === 0 && (
    <>
    
        {!reviewFlg && (
            <>
            <div className="container d-flex justify-content-center">
            <div className="card p-3 mb-3 shadow-sm" style={{ maxWidth: "600px", width: "100%" }}>
            <h4 className="text-center">Edit Bill Params</h4>
            <form onSubmit={handleSubmit}>
                {searchParams.map((param, index) => (
                    <div key={index} className="border rounded p-2 mb-3">
                    <div className="row g-2">
                        {/* Bill ID */}
                        <div className="col-6">
                        <label className="form-label small">Bill ID</label>
                        <input
                            type="text"
                            className="form-control form-control-sm"
                            name="bill_id"
                            value={param.bill_id}
                            onChange={(e) => handleInputChange(index, e)}
                            required
                            disabled={initial_bill_ids.includes(param.bill_id)}
                        />
                        </div>

                        {/* Bill Name */}
                        <div className="col-6">
                        <label className="form-label small">Bill Name</label>
                        <input
                            type="text"
                            className="form-control form-control-sm"
                            name="bill_name"
                            value={param.bill_name}
                            onChange={(e) => handleInputChange(index, e)}
                            required
                        />
                        </div>
                    </div>

                    <div className="row g-2">
                        {/* Start & End Timestamp */}
                        <div className="col-6">
                        <label className="form-label small">Start Time</label>
                        <input
                            type="datetime-local"
                            className="form-control form-control-sm"
                            name="start_ts"
                            value={formatTimestamp(param.start_ts)}
                            onChange={(e) => handleInputChange(index, e)}
                            required
                        />
                        </div>
                        <div className="col-6">
                        <label className="form-label small">End Time</label>
                        <input
                            type="datetime-local"
                            className="form-control form-control-sm"
                            name="end_ts"
                            value={formatTimestamp(param.end_ts)}
                            onChange={(e) => handleInputChange(index, e)}
                            required
                        />
                        </div>
                    </div>

                    {/* Filters */}
                    <div className="row g-2">
                        <div className="col-6">
                        <label className="form-label small">Subject Filter</label>
                        <input
                            type="text"
                            className="form-control form-control-sm"
                            name="subject_filter"
                            value={param.subject_filter || ""}
                            onChange={(e) => handleInputChange(index, e)}
                        />
                        </div>
                        <div className="col-6">
                        <label className="form-label small">From Filter</label>
                        <input
                            type="text"
                            className="form-control form-control-sm"
                            name="from_filter"
                            value={param.from_filter || ""}
                            onChange={(e) => handleInputChange(index, e)}
                        />
                        </div>
                    </div>

                    <div className="row g-2">
                        <div className="col-6">
                        <label className="form-label small">Balance Regex</label>
                        <input
                            type="text"
                            className="form-control form-control-sm"
                            name="balance_regex"
                            value={param.balance_regex}
                            onChange={(e) => handleInputChange(index, e)}
                            required
                        />
                        </div>
                        <div className="col-6">
                        <label className="form-label small">Additional Search Args</label>
                        <textarea
                            className="form-control form-control-sm"
                            name="additional_search_args"
                            rows="1"
                            value={param.additional_search_args || ""}
                            onChange={(e) => handleInputChange(index, e)}
                        />
                        </div>
                    </div>

                    {/* Remove Button */}
                    <div className="text-end mt-2">
                        <button type="button" className="btn btn-sm btn-danger" onClick={() => removeSearchParam(index)}>Remove</button>
                    </div>
                    </div>
                ))}

                {/* Add & Save Buttons */}
                <div className="d-flex justify-content-between">
                    <button type="button" className="btn btn-sm btn-success" onClick={addNewSearchParam}>+ Add Bill</button>
                    <button type="submit" className="btn btn-sm btn-primary">Review Changes</button>
                </div>
            </form>
            </div>
            </div>
            </>
        )}

        {reviewFlg && (
        <>
            <div className="container d-flex justify-content-center">
            <div className="card p-3 mb-3 shadow-sm" style={{ maxWidth: "600px", width: "100%" }}>
            {/* Show Only Changed Fields */}
            {modifiedParams.length > 0 && (
                <div className="mt-4">
                <h3>Modified Params</h3>
                <ul className="list-group">
                    {modifiedParams.map((param, index) => (
                    <li key={index} className="list-group-item">
                        <strong>Bill ID: {param.bill_id}</strong>
                        <ul>
                            {Object.entries(param).map(([key, value]) =>
                                key !== "bill_id" ? (
                                <li key={key}>
                                    <strong>{key}:</strong> {value}
                                </li>
                                ) : null
                            )}
                        </ul>
                    </li>
                    ))}
                </ul>
                </div>
            )}


            {removedParams.length > 0 && (
                <div className="mt-4">
                <h3>Removed Params</h3>
                <ul className="list-group">
                    {removedParams.map((bill_id, index) => (
                        <li key={index} className="list-group-item">
                        <strong>Bill ID: {bill_id}</strong>
                        <ul>
                            {Object.entries(findByID(bill_id, searchParams)).map(([key, value]) =>
                                key !== "bill_id" ? (
                                <li key={key}>
                                    <strong>{key}:</strong> {value}
                                </li>
                                ) : null
                            )}
                        </ul>
                        </li>
                    ))}
                </ul>
                </div>
            )}
            

            {createdParams.length > 0 && (
                <div className="mt-4">
                <h3>Created Params</h3>
                <ul className="list-group">
                    {createdParams.map((bill_id, index) => (
                        <li key={index} className="list-group-item">
                        <strong>Bill ID: {bill_id}</strong>
                        <ul>
                            {Object.entries(findByID(bill_id, searchParams)).map(([key, value]) =>
                                key !== "bill_id" ? (
                                <li key={key}>
                                    <strong>{key}:</strong> {value}
                                </li>
                                ) : null
                            )}
                        </ul>
                        </li>
                    ))}
                </ul>
                </div>
            )}

            <div className="d-flex justify-content-between mt-4">
                <button type="submit" className="btn btn-sm btn-primary" onClick={() => setReviewFlg(false)}>Back</button>
                <button type="submit" className="btn btn-sm btn-primary" onClick={submitChanges}>Submit Changes</button>
            </div>
            </div>
            </div>
        </>
        )}
    </> )}
    </>
    );
}

export default BillParamsAdmin;
