import { useState, useEffect } from "react";
import {Redirect, Link} from "react-router-dom";
import {GetUserHomeData} from '../utils/queries';
import LoadingPage from "./loading";
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import {formatTimestamp, capitalizeStr, encryptData, decryptData} from '../utils/general_functions';

function Home(props) {
    const authState = props.authState;
    const oktaAuth = props.oktaAuth;
    const [userData, setUserData] = useState();
    // const { data: userData, setData: setUserData } = useContext(DataContext);
    const [userGroups, setUserGroups] = useState([]);

    useEffect(() => { 
        if (!userGroups | userGroups.length === 0) {
            oktaAuth.getUser().then((data) => setUserGroups(data.groups));
        }
    }, [authState]);


    useEffect(() => {
        // We want to make sure the userGroups load have finished
        if (!userData & userGroups.length > 0) {
            // This should always exist, at least on this page
            // const authState = JSON.parse(localStorage.getItem('okta-token-storage'));
            const lsUserData = localStorage.getItem('userData');
            if (lsUserData !== null & typeof lsUserData !== 'undefined') {
                // console.log('USING LOCAL STORAGE');
                setUserData(JSON.parse(decryptData(lsUserData)));
            } else {
                // console.log('FETCHING FROM DB');
                GetUserHomeData(authState.accessToken.claims.uid, userGroups)
                .then((data) => {setUserData(data); localStorage.setItem('userData', encryptData(JSON.stringify(data)));})
                .catch((error) => {console.error(error)});
            }
        }
    }, [userGroups]);

    
    if (!userData) { return <LoadingPage/> }
    if (userData === -1) {
        // No user found
        alert(`USER NOT IN DB, PLEASE CONTACT ADMIN, YOUR UID: ${authState.accessToken.claims.uid}`)
        return <Redirect to="/logout"/>
    }
    
    let lastSignInMsg = `You last sign in was never`;
    if (userData.lastAccessed) {
        lastSignInMsg = `You last sign in was at ${formatTimestamp(userData.lastAccessed)}`;
    }

    const userApps = userData.apps.filter(currApp => !currApp.is_admin_page);
    if (userData.apps.length !== userApps.length) {
        userApps.push({app_name: 'admin', app_img_link: 'wrench_icon.webp'})
    }

    return (
        <div>
            <p>Welcome back {userData.firstName} {userData.lastName}!</p>
            <p>{lastSignInMsg}</p>
            <Row xs={1} md={4} className="g-4">
            {userApps.map((currApp, idx) => (
                <Col key={idx}>
                <Link to={`/${currApp.app_name}`}>
                    <Card>
                        <Card.Img variant="top" src={currApp.app_img_link} style={{width:100, height:100, margin: "auto", marginTop: 10}}/>
                        <Card.Body>
                        <Card.Title>{capitalizeStr(currApp.app_name.replace(/_/g, ' '))}</Card.Title>
                        </Card.Body>
                    </Card>
                </Link>
                </Col>
            ))}
            </Row>
        </div>
    )
}

export default Home;