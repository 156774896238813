import { loadStripe } from "@stripe/stripe-js";
import React, { useState, useEffect } from "react";
import { Container, Col, Row, Button } from "react-bootstrap";
import { Route, Redirect, Switch, Link } from "react-router-dom";

import { GetBillsByUserID } from "../../utils/queries";
import {roundDecimal, decryptData} from "../../utils/general_functions";

// Components
import LoadingPage from "../loading";
import CheckoutForm from "./checkout_form";
import CheckoutReturn from "./checkout_return";

function getColStyle(idx, col_num) {
    let style = { borderRight: "solid", borderTop: "solid" };
    // If it is a header row and there are 0 bills OR the current index + 1 = maxIdx, AKA the last row
    if (idx === "cartTotal") {
        style.borderBottom = "solid";
    }
    if (col_num === 0) {
        style.borderLeft = "solid";
    }
    return style;
}


function BillTracker() {
    const [cart, setCart] = useState([]);
    const [billData, setBillData] = useState();
    const [stripePromise, setStripe] = useState();
    const userData = JSON.parse(decryptData(localStorage.getItem('userData')));
    // const { data: userData, setData: setUserData } = useContext(DataContext);
    
    useEffect(() => {
        if (!stripePromise) {
            loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY).then((stripe) => setStripe(stripe));
        }
    }, []);
    
    useEffect(() => { 
        if (!billData) {
            GetBillsByUserID(userData.userID).then((data) => setBillData(data));
        }
    }, [userData]);
    
    if (!billData | !stripePromise) { return <LoadingPage/> }

    const rowStyle = { backgroundColor: "limegreen" };
    const buttonStyling = {fontSize:"80%", height: "90%", width: "80%", margin: "auto", marginTop: ".5%", textAlign: "center"};

    function BillTrackerComp() {
        return (
            <Container>
            <Row style={rowStyle} key={"header"}>
                <Col sm={3} style={getColStyle("header", 0)}>Bill Type</Col>
                <Col sm={3} style={getColStyle("header", 1)}>Balance</Col>
                <Col sm={3} style={getColStyle("header", 2)}>Date Received</Col>
                <Col sm={3} style={getColStyle("header", 3)}>Pay Now</Col>
            </Row>
            {billData.map((currBill, idx) => (
                <Row style={rowStyle} key={idx}>
                    <Col sm={3} style={getColStyle(idx, 0)}>{currBill.bill_name}</Col>
                    <Col sm={3} style={getColStyle(idx, 1)}>{`$${roundDecimal(currBill.balance).toFixed(2)}`}</Col>
                    <Col sm={3} style={getColStyle(idx, 2)}>{`${currBill.msg_ts.substring(0, 10)} ${currBill.msg_ts.substring(11, 19)}`}</Col>
                    <Col sm={3} style={getColStyle(idx, 3)}>
                        {cart.includes(currBill.bill_row_id) ? (
                            <Button variant="secondary" onClick={() => setCart(cart.filter((item) => item !== currBill.bill_row_id))} style={buttonStyling}>Remove from cart</Button>
                        ) : (
                            <Button variant="secondary" onClick={() => setCart([...cart, currBill.bill_row_id])} style={buttonStyling}>Add to cart</Button>                        
                        )}
                    </Col>
                </Row>
            ))}

            <Row style={rowStyle} key={"cartTotal"}>
                <Col sm={3} style={getColStyle("cartTotal", 0)}>Cart Total: {`$${roundDecimal(billData.reduce((sum, item, idx) => sum + (cart.includes(`${item.bill_row_id}`) ? item.balance : 0), 0)).toFixed(2)}`}</Col>
                <Col sm={3} style={getColStyle("cartTotal", 1)}><Button variant="secondary" onClick={() => {setCart(billData.map(currBill => (currBill.bill_row_id)))}} style={buttonStyling}>Add All To Cart</Button></Col>
                <Col sm={3} style={getColStyle("cartTotal", 2)}><Button variant="secondary" onClick={() => {setCart([])}} style={buttonStyling}>Clear Cart</Button></Col>
                <Col sm={3} style={getColStyle("cartTotal", 3)}>
                    {cart.length > 0 ? (
                        <Link to="/bill_tracker/checkout"><Button variant="secondary" style={buttonStyling}>Check Out</Button></Link>
                    ) : (
                        <Button variant="secondary" onClick={() => {alert('No items in cart')}} style={buttonStyling}>Check Out</Button>
                    )}
                </Col>
            </Row>
            </Container>
        )
    }

    return (
        <Switch>
            {/* Billing specific routes */}
            <Route exact path="/bill_tracker" component={BillTrackerComp}/>
            <Route exact path="/bill_tracker/checkout" render={() => <CheckoutForm {...{stripePromise: stripePromise, cart: billData.filter((bill, idx) => cart.includes(`${bill.bill_row_id}`))}}/>}/>
            <Route exact path="/bill_tracker/return" component={CheckoutReturn}/>
            <Redirect from="/bill_tracker/*" to="/404"/>
        </Switch>
    );
}

export default BillTracker;
