import React, { useState, useEffect } from "react";
import { Container, Col, Row } from "react-bootstrap";
import LoadingPage from "./loading";


function getColStyle(idx, col_num, status) {
    let style = { borderRight: "solid", borderTop: "solid" };

    if (col_num === 1 && status && status === 'offline') {
        style.backgroundColor = 'red';
    }
    if (col_num === 0) {
        style.borderLeft = "solid";
    }
    return style;
}


function ServerStatus() {
    const [serverData, setServerData] = useState(null);

    useEffect(() => {
        // Create a Checkout Session as soon as the page loads
        fetch(`${window.location.origin}/api/get-server-status`, {
            method: "GET"
        }).then((res) => {
            if (!res.ok) {
                return Promise.reject(res);
            }
            return res.json();
        })
        .then((data) => setServerData(data))
        .catch(error => {console.error(error)});
    }, []);

    if (!serverData) {
        return <LoadingPage/>;
    }

    const rowStyle = { backgroundColor: "limegreen" };

    return (
        <Container>
            <Row style={rowStyle} key={"header"}>
                <Col sm={4} style={getColStyle("header", 0)}>Server Name</Col>
                <Col sm={4} style={getColStyle("header", 1)}>Status</Col>
                <Col sm={4} style={getColStyle("header", 2)}>Last Updated</Col>
            </Row>
            {serverData.map((currServer, idx) => (
                <Row style={rowStyle} key={idx}>
                    <Col sm={4} style={getColStyle(idx, 0)}>{currServer.server_name}</Col>
                    <Col sm={4} style={getColStyle(idx, 1, currServer.status)}>{currServer.status}</Col>
                    <Col sm={4} style={getColStyle(idx, 2)}>{currServer.timestamp}</Col>
                </Row>
            ))}

        </Container>
    );
}

export default ServerStatus;