import React from "react";
import { Route, Redirect, Switch, Link } from "react-router-dom";
import { useOktaAuth } from "@okta/okta-react";

import LoginPage from "./components/login";
import LogoutPage from "./components/logout";
import HomePage from "./components/home";
import LoadingPage from "./components/loading";
import Render404Error from "./components/render404Error";
import PageInProgress from "./components/page_in_progress";
import BillTracker from "./components/billing/bill_tracker";
import ServerStatus from "./components/server_status";
import AdminPage from "./components/admin";


function Home() {
    // Returns the oktaAuth instance and the current authState
    const { oktaAuth, authState } = useOktaAuth();

    // Check to make sure authState exists, usually a slight delay
    // This check will prevent the page from flickering the not logged in page
    if (!authState || !oktaAuth) {
        return <LoadingPage/>;
    }

    // Functions to handle login/logout
    const login = async () => oktaAuth.signInWithRedirect();
    const logout = async () => oktaAuth.signOut("/");

    // console.log(`Logged in/out status: ${authState?.isAuthenticated}`);
    return (
        <div className="App">
            <main role="main" className="inner cover container">
                {/* Login/out btns */}
                <nav className="navbar navbar-expand-lg navbar-light bg-light">
                    {/* If logged in then show logout button and call logout function if pressed */}
                    {authState?.isAuthenticated && (
                        <>
                        <Link to="/" className="btn btn-outline-secondary my-2 my-sm-0">Home</Link>
                        <button className="btn btn-outline-secondary my-2 my-sm-0 ms-auto" onClick={logout}>Logout</button>
                        </>
                    )}
                    {/* If logged out then show login button and call login function if pressed */}
                    {!authState?.isAuthenticated && (
                        <button className="btn btn-outline-secondary my-2 my-sm-0 ms-auto" onClick={login}>Login</button>
                    )}
                </nav>

                {/* If not logged in redirect to login page */}
                {!authState?.isAuthenticated && (  
                    <div>
                        <Route path="/login" component={LoginPage}/>
                        <Redirect to='/login'/>
                    </div>
                )}

                {/* If logged in  */}
                {authState?.isAuthenticated && (
                    <Switch>
                        <Route exact path="/logout" render={() => {<LogoutPage/>;logout()}}/>
                        <Route exact path="/" render={() => <HomePage {...{authState: authState, oktaAuth: oktaAuth}}/>}/>                        
                        <Route exact path="/powerserv_server_status" component={ServerStatus}/>
                        {/* This path cannot be exact or sub routes wont work */}
                        <Route path="/bill_tracker" component={BillTracker}/>
                        <Route path="/admin" component={AdminPage}/>
                        <Route path="/404" component={Render404Error} />
                        <Redirect to="/404"/>
                    </Switch>
                )}


                <footer className="bg-light text-center relative-bottom" style={{width: "100%", padding: "0 15px"}}>
                    <p>Powerserv 2023</p>
                </footer>
            </main>
        </div>
    );
}

export default Home;