// https://learn.microsoft.com/en-us/azure/static-web-apps/database-azure-sql?tabs=bash&pivots=static-web-apps-rest
async function UpdateUserLastLoginTime(userID) {
    const currentTimestamp = new Date().toLocaleString('en-US');
    const gql = {
        query: `mutation update($user_id: String!, $item: Updateuser_last_accessedInput!) {
            updateuser_last_accessed(user_id: $user_id, item: $item) {
                user_id
                last_accessed
            }
        }`,
        variables: {
            user_id: userID,
            item: {last_accessed: currentTimestamp}
        } 
    };

    await fetch(process.env.REACT_APP_GRAPHQL_ENDPOINT, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(gql)
    });

}


// We need this for async react
async function GetUserLastLoginTime(userID) {
    const gql = {
        // I have not idea why it is called user_last_accesseds or why it works
        query: `query GetUserLastLoginTime($userID: String!){
            user_last_accesseds(filter: {user_id: {eq: $userID}})
            {
                items{
                   last_accessed  
                }
            }
        }`,
        variables: {
            userID: userID
        } 
    };

    const lastAccessed = await fetch(process.env.REACT_APP_GRAPHQL_ENDPOINT, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(gql)
    })
    .then((res) => res.json()).then((res) => res.data.user_last_accesseds.items)
    .catch((error) => console.error(error));

    if (lastAccessed.length === 1) {
        return lastAccessed[0]['last_accessed'];
    }

    // if there is no entry for a user we want to create one for the future
    await fetch(`${process.env.REACT_APP_REST_ENDPOINT}/user_last_accessed/`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({user_id: userID})
    });

    return undefined;
}


async function GetUserHomeData(UID, userGroups) {
    // Let them run at the same time
    const [userData, lastAccessed, appData] = await Promise.all([GetUserDataByUID(UID), GetUserLastLoginTime(UID), GetAppDataFromGroupNames(userGroups)]);

    // User not found
    if (userData === -1) {return -1}

    // There isnt really a need for the await but what evs
    await UpdateUserLastLoginTime(UID);
    
    // Join everything together
    return {...userData, lastAccessed, apps:appData};
}


// We need this for async react
async function GetAppDataFromGroupNames(userGroups) {
    // The everyone role does not matter or correlate to a page
    userGroups = userGroups.filter(group => group !== 'Everyone');
    // Create the filters
    userGroups = userGroups.map((group) => `{app_group_name: {eq: "${group}"}}`);
    
    const gql = {
        query: `query GetAppDataFromGroupNames{
            apps(filter: {or: [${userGroups.join(', ')}]})
            {
                items{
                    app_name,
                    app_img_link,
                    is_admin_page
                }
            }
        }`
    };

    const appData = await fetch(process.env.REACT_APP_GRAPHQL_ENDPOINT, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(gql)
    })
    .then((res) => res.json()).then((res) => res.data.apps.items)
    .catch((error) => console.error(error));

    return appData;
}


// We need this for async react
async function GetAllUserBills() {
    const gql = {
        query: `query GetAllUserBills(){
            user_bills {
                items{
                    user_id,
                    bill_id,
                    bill_name,
                    split_type,
                    split_value,
                    start_dt,
                    end_dt,
                    is_active,
                    user_bill_id
                }
            }
        }`
    };

    const userBillsData = await fetch(process.env.REACT_APP_GRAPHQL_ENDPOINT, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(gql)
    })
    .then((res) => res.json()).then((res) => res.data.user_bills.items)
    .catch((e) => {
        console.error(e); alert(e);
    }) || [];

    // userBillsData.forEach(
    //     (userBill, idx) => {
    //         userBillsData[idx] = {...userBill, user_first_name: userBill.users.first_name, user_last_name: userBill.users.last_name};
    //         delete userBillsData[idx]['users'];
    // })

    return userBillsData;
}


async function GetAllUsers() {
    const gql = {
        query: `query GetAllUsers(){
            users {
                items{
                    user_id,
                    first_name,
                    last_name,
                    email
                }
            }
        }`
    };

    const userData = await fetch(process.env.REACT_APP_GRAPHQL_ENDPOINT, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(gql)
    })
    .then((res) => res.json())
    .then((res) => res.data.users.items)
    .catch((e) => {
        console.error(e); alert(e);
    }) || [];

    return userData;

}


// We need this for async react
async function GetBillSearchParams() {
    // bill_search_params()
    // filter: {bill_id : {eq: "ATHERTON_ENERGY"}}
    const gql = {
        query: `query GetBillSearchParams{
            bill_search_params()
            {
                items{
                    bill_id
                    , bill_name
                    , start_ts
                    , end_ts
                    , subject_filter
                    , from_filter
                    , balance_regex
                    , additional_search_args
                    , row_num
                }
            }
        }`
    };

    const billParamData = await fetch(process.env.REACT_APP_GRAPHQL_ENDPOINT, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(gql)
    }).then((res) => res.json()).then((res) => res.data.bill_search_params.items)
    .catch((error) => console.error(error));

    return billParamData;
}


// We need this for async react
async function UpdateBillSearchParams(billSearchParams) {

    let cnt = 0;
    billSearchParams.forEach(bill => {
        const { bill_id, ...cols_to_update } = bill;
        const gql = {
            query: `mutation update($bill_id: String!, $cols_to_update: Updatebill_search_paramsInput!) {
                updatebill_search_params(bill_id: $bill_id, item: $cols_to_update) {
                    bill_id
                }
            }`,
            variables: {
                bill_id: bill.bill_id,
                cols_to_update: cols_to_update
            } 
        };

        fetch(process.env.REACT_APP_GRAPHQL_ENDPOINT, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(gql)
        })
        .then((res) => {
            if (!res.ok) {
                return Promise.reject(res);
            }
            return res.json();
        })
        .catch((e) => {
            e.json().then((e) => {const error = e.errors; alert(error.map((i) => i.message)); console.error(error); });
        });

        cnt += 1;
    });

    return cnt;

}


// We need this for async react
async function GetUserDataByUID(UID) {
    const gql = {
        query: `query GetUserDataByUID($UID: String!){
            users(filter: {user_id: {eq: $UID}})
            {
                items{
                    user_id,
                    first_name,
                    last_name
                }
            }
        }`,
        variables: {
            UID: UID
        } 
    };

    const userData = await fetch(process.env.REACT_APP_GRAPHQL_ENDPOINT, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(gql)
    })
    .then((res) => res.json())
    .then((res) => res.data.users.items)
    .catch((error) => console.error(error));

    // Ideally userData.length === 0 is the only condition that will hit
    if (!userData || userData.length === 0 || (userData.length === 1 && !userData[0]))
    {
        return -1;
    }
    // This should have only one user in it
    else if (userData.length === 1 && userData[0])
    {
        return {
            userID: userData[0].user_id,
            firstName: userData[0].first_name,
            lastName: userData[0].last_name
        };
    }
    // This should never happen since we have the unique constraint in sql
    else if (userData.length > 1) {
        throw new Error('ERROR FETCHING USER INFO, MULTIPLE USERS WITH SAME UID')
    }
}


// We need this for async react
async function GetBillsByUserID(UserID) {
    const gql = {
        query: `query GetBillsByUID($UserID: String!){
                bills(filter: { and: [ 
                        { is_paid: { neq: 1 } }
                        { user_id: {eq: $UserID} }
                        ]
                    }
                ) {
                items{
                    bill_row_id,
                    bill_name,
                    balance,
                    msg_ts
                }
            }
        }`,
        variables: {
            UserID: UserID
        } 
    };

    const billData = await fetch(process.env.REACT_APP_GRAPHQL_ENDPOINT, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(gql)
    })
    .then((res) => res.json())
    .then((res) => res.data.bills.items)
    .catch((error) => console.error(error));
    
    // No user was found with id, this should not happen since this is already checked in home page
    if (!billData) {
        return;
    } else if (billData.length === 0) {
        return [];
    } else {     
        // Sort by the date they were loaded
        return billData.sort(function(a, b) {
            if (a.msg_ts < b.msg_ts) {
                return 1
            }
            else if (a.msg_ts > b.msg_ts) {
                return -1
            }
            return 0
        });
    }
}


async function UpdatePaidBills(billData) {
    billData.forEach(bill => {
        const gql = {
            query: `mutation update($bill_row_id: String!, $cols_to_update: UpdatebillsInput!) {
                updatebills(bill_row_id: $bill_row_id, item: $cols_to_update) {
                    bill_row_id
                }
            }`,
            variables: {
                bill_row_id: bill.bill_row_id,
                cols_to_update: {is_paid: 1, paid_on: new Date()}
            } 
        };
    
        fetch(process.env.REACT_APP_GRAPHQL_ENDPOINT, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(gql)
        });
    });
}

export {GetUserHomeData, GetBillsByUserID, UpdatePaidBills, GetBillSearchParams, UpdateBillSearchParams, GetAllUserBills, GetAllUsers};