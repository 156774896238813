import CryptoJS from "crypto-js";

function capitalizeStr(str) {
    return str.split(" ").map((word) => word[0].toUpperCase() + word.substr(1) + ' ');
}

function roundDecimal(num, scale=2) {
    const scaler = 10 ** scale;    
    return (Math.round(num * scaler) / scaler)
}

function formatTimestamp(ts) {
    return `${ts.substring(0, 10)} ${ts.substring(11, 19)}`
}

function encryptData(data) {
    return CryptoJS.AES.encrypt(data, process.env.REACT_APP_STRING_ENCRYPTION_KEY).toString();
}

function decryptData(data) {
    return CryptoJS.AES.decrypt(data, process.env.REACT_APP_STRING_ENCRYPTION_KEY).toString(CryptoJS.enc.Utf8)
}

export {capitalizeStr, roundDecimal, formatTimestamp, encryptData, decryptData};
